<mat-card>
  <mat-card-content>
    <mat-card-title>Select Azure Resource Types</mat-card-title>
    <mat-form-field>
      <mat-label>Azure Resource Types</mat-label>
      <input matInput type="text" [matAutocomplete]="azureResourceTypeAutocomplete" [formControl]="selector" />
      <mat-autocomplete #azureResourceTypeAutocomplete="matAutocomplete" (optionSelected)="onResourceTypeSelected($event)">
        @for (resourceType of filteredResourceTypes | async; track resourceType.value) {
          <mat-option [value]="resourceType">
            <mat-icon [svgIcon]="resourceType.image"></mat-icon>
            {{ resourceType.displayName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <mat-chip-listbox>
      @for (resourceType of formGroup.value.selectedTypes; track resourceType.value) {
        <mat-chip (removed)="removeResourceType(resourceType)" [disabled]="readOnly">
          <mat-icon [svgIcon]="resourceType.image"></mat-icon>
          {{ resourceType.displayName }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      }
    </mat-chip-listbox>
  </mat-card-content>
</mat-card>
