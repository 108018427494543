import { Time } from '@angular/common';
import { ApplicationTier } from '../../models/enums/application-tier.enum';
import { ApprovalType } from '../../models/enums/approval-type.enum';
import { DayOfWeek } from '../../models/enums/dey-of-week.enum';
import { DeploymentScopeInclusionType } from '../../views/create-deployment-scope/create-deployment-scope.model';

export interface IResourcesScopingModel {
  subscriptions: string[];
  resourceGroups: string[];
  resources: string[];
}

export interface IDigitalPlatformScopingModel {
  digitalPlatformId: number;
  applicationTiers: ApplicationTier[];
}

export interface MaintenanceScheduleRequestModel {
  name: string;
  description: string;
  timeZone: string;
  startTime: string;
  duration: string;
  tagName: string;
  interval: number;
  dayOfWeek: DayOfWeek;
  monthOccurrence: number;
  offsetInDays: number;
}

export interface DeploymentScopeResponseModel {
  automationProcess: AutomationProcesResponseModel;
  inclusionType: DeploymentScopeInclusionType;
  resourceTypes: string[];
  tags: Record<string, string>;
  maintenanceSchedule?: MaintenanceScheduleResponseModel;
  resources: ResourceResponseModel[];
  digitalPlatform: DigitalPlatformResponseModel;
}

export interface AutomationProcesResponseModel {
  id: number;
  name: string;
  description: string;
  type: string;
  approvalTypes: ApprovalType[];
  businessCapability: string;
  digitalPlatform: string;
  supportGroup: string;
}

export interface MaintenanceScheduleResponseModel {
  name: string;
  description: string;
  timeZone: string;
  startTime: string;
  duration: string;
  tagName: string;
  interval: number;
  dayOfWeek: DayOfWeek;
  offsetInDays: number;
  monthOccurrence: number;
  frequency: string;
}

export interface ResourceResponseModel {
  id: string;
  name: string;
  resourceType: string;
}

export interface DeploymentScopeReviewersResponse {
  result: DeploymentScopeReviewer[];
}

export interface DeploymentScopeReviewer {
  name: string;
  email: string;
  status: DeploymentScopeReviewerStatus;
  comment?: string;
}

export enum DeploymentScopeReviewerStatus {
  Pending = 'Pending',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export interface DigitalPlatformResponseModel {
  id: number;
  name: string;
  tiers: ApplicationTier[];
}
