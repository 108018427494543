import { Component, Input, OnInit, inject, input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OptionsService } from '../../../services/options/options.service';
import { ITimeZoneModel } from '../../../services/options/options.model';
import { TooltipPosition } from '@angular/material/tooltip';
import { Observable, map, startWith, tap } from 'rxjs';
import { MaintenanceScheduleForm } from './maintenance-schedule.component.model';
import { DayOfWeek, DayOfWeekHelper } from '../../../models/enums/dey-of-week.enum';
import { Time } from '@angular/common';
import moment, { Moment } from 'moment';

@Component({
  selector: 'maintenance-schedule',
  templateUrl: './maintenance-schedule.component.html',
})
export class MaintenanceScheduleComponent implements OnInit {
  @Input() public maintenanceSchedule: FormGroup<MaintenanceScheduleForm>;
  @Input() public readonly: Boolean = false;

  private optionsService = inject(OptionsService);
  defaultTooltipPosition: TooltipPosition = 'left';

  timeZones: ITimeZoneModel[];
  filteredTimeZones: Observable<ITimeZoneModel[]>;
  timeZonesLoading: boolean = true;

  daysOfWeek: DayOfWeek[] = DayOfWeekHelper.getAllDays();

  durationMaxValue: Date = moment().hour(3).minutes(55).toDate();

  ngOnInit(): void {
    this.optionsService
      .getTimeZones()
      .pipe(tap(() => (this.timeZonesLoading = false)))
      .subscribe((sub) => {
        this.timeZones = sub;

        this.filteredTimeZones = this.maintenanceSchedule.controls.timeZone.valueChanges.pipe(
          startWith(''),
          map((value: string) => this.filterTimeZones(value || '')),
        );
      });

    if (this.readonly) {
      this.maintenanceSchedule.disable();
    }
  }

  private filterTimeZones(value: string): ITimeZoneModel[] {
    // temporary workaround until root cause is found
    if (typeof value !== 'string') {
      value = '';
    }
    let searchValue = value.toLowerCase();
    searchValue = this.escapeRegExCharacters(searchValue);

    return this.timeZones.filter((filterValue) => filterValue.displayName.toLowerCase().search(searchValue) > -1);
  }

  private escapeRegExCharacters(value: string): string {
    return value.replace(/[^A-Za-z0-9_]/g, '\\$&');
  }

  displayTimeZone(timeZones: ITimeZoneModel[]): (timeZoneId: string) => string {
    return (timeZoneId: string) => {
      return timeZones.find((x) => x.id === timeZoneId)?.displayName ?? '';
    };
  }

  public static buildMaintenanceScheduleForm(): FormGroup<MaintenanceScheduleForm> {
    const startTime = moment().hours(12).minutes(0);
    const duration = moment().hours(3).minutes(55);

    return new FormGroup<MaintenanceScheduleForm>({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      startTime: new FormControl<Moment>(startTime, Validators.required),
      duration: new FormControl<Moment>(duration, Validators.required),
      tagName: new FormControl('', Validators.required),
      interval: new FormControl(1, { validators: [Validators.required, Validators.min(1), Validators.max(3)] }),
      dayOfWeek: new FormControl<DayOfWeek>(DayOfWeek.Monday, Validators.required),
      offsetInDays: new FormControl(0, { validators: [Validators.required, Validators.min(0), Validators.max(6)] }),
      monthOccurrence: new FormControl(1, { validators: [Validators.required, Validators.min(1), Validators.max(4)] }),
      frequency: new FormControl('Month'),
    });
  }
}
