import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageLayout } from '@cvx/nextpage';
import { catchError, throwError } from 'rxjs';
import { AutomationProcessService } from '../../services/automation-process/automation-process.service';
import { IAzureResourceType } from '../../models/azure-models/azure-resource-type.model';
import { ActivatedRoute, Params } from '@angular/router';
import { ApplicationTier } from '../../models/enums/application-tier.enum';
import { DeploymentScopeInclusionType, DeploymentScopeType } from './create-deployment-scope.model';
import { IDigitalPlatform } from '../../services/service-now-resources.service/service-now-resources.model';
import { IAutomationProcessBusinessCapabilityDetails, IAutomationProcessResponse } from '../../services/automation-process/automation-process.model';
import { DeploymentScopesService } from '../../services/deployment-scope/deployment-scope.service';
import { DeploymentScopeResponseModel, MaintenanceScheduleRequestModel } from '../../services/deployment-scope/deployment-scope.model';
import { ToastService } from '../../services/toast/toast.service';
import { MaintenanceScheduleComponent } from './maintenance-schedule/maintenance-schedule.component';
import { MaintenanceScheduleForm } from './maintenance-schedule/maintenance-schedule.component.model';
import { AutomationProcessSelectorComponent } from './automation-process-selector/automation-process-selector.component';
import moment from 'moment';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { ResourcesSelectorComponent } from './resources-selector/resources-selector.component';
import { ScopingTypeComponent } from './scoping-type/scoping-type.component';
import { IAzureResource, IAzureResourceGroup, IAzureSubscription } from '../../services/azure-service.service/azure-service.model';
import { MatDialog } from '@angular/material/dialog';
import { ApproveOperationDialog } from './dialogs/approve-operation/approve-operation-dialog';
import { DeploymentScopeDataService } from './service/deployment-scope-data.service';
// import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';

export interface IApplicationTierSelection {
  name: string;
  value: ApplicationTier;
  selected: boolean;
}

@Component({
  selector: 'create-deployment-scope-view',
  templateUrl: './create-deployment-scope.view.html',
  styleUrls: ['./create-deployment-scope.view.scss'],
})
export class CreateDeploymentScopeView implements OnInit {
  private _automationProcessService = inject(AutomationProcessService);
  private deploymentScopesService = inject(DeploymentScopesService);
  private deploymentScopeDataService = inject(DeploymentScopeDataService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);
  dialog = inject(MatDialog);

  scopingTypeOptions = DeploymentScopeType;
  inclusionTypeOptions = DeploymentScopeInclusionType;
  readOnlyMode: boolean = false;
  currentUserInAutomationProcessReviewerList: boolean = false;

  selectedDigitalPlatforms: IDigitalPlatform[] = [];
  resourceTypes: IAzureResourceType[] = [];
  selectedApplicationTiers: ApplicationTier[] = [];
  PageLayout = PageLayout;
  deploymentScopeId: number;

  deploymentScope = new FormGroup({
    maintenanceSchedule: MaintenanceScheduleComponent.buildMaintenanceScheduleForm(),
    automationProcess: AutomationProcessSelectorComponent.buildAutomationProcessSelectorForm(),
    inclusion: new FormControl<DeploymentScopeInclusionType>(null, Validators.required),
    tags: TagFilterComponent.buildTagFilterForm(),
    resourceTypes: ResourcesSelectorComponent.buildResourcesSelector(),
    scopingType: ScopingTypeComponent.buildScopingType(),
  });

  onSubmitDeploymentScope(): void {
    const resourceTypes = this.deploymentScope.value.resourceTypes.selectedTypes.map((type) => type.value);
    const automationProcess = this.deploymentScope.controls.automationProcess.controls.automationProcess?.value;
    const inclusionType = this.deploymentScope.controls.inclusion?.value;
    let maintenanceSchedule: MaintenanceScheduleRequestModel | null;

    const [resourcesScoping, digitalPlaformScope, managementGroupIds] = ScopingTypeComponent.getScopingModels(
      this.deploymentScope.controls.scopingType,
    );

    if (automationProcess.requiresMaintenanceSchedule) {
      maintenanceSchedule = this.getMaintenanceSchedule(this.deploymentScope.controls.maintenanceSchedule);
    }

    this.deploymentScopesService
      .createDeploymentScope(
        automationProcess.id,
        resourceTypes,
        inclusionType,
        this.deploymentScope.value.tags.tags,
        resourcesScoping,
        digitalPlaformScope,
        maintenanceSchedule,
        managementGroupIds,
      )
      .pipe(
        catchError((err) => {
          this.toastService.showError('Failed to created deployment scope.');

          return throwError(() => err);
        }),
      )
      .subscribe((sub) => {
        this.toastService.showSuccess('Deployment scope has been created!.');
      });
  }

  onApproveDeploymentScope(): void {
    const dialogRef = this.dialog.open(ApproveOperationDialog, {
      data: {
        userEmail: 'test@chevron.com',
        headerText: 'Approve Deployment Scope',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result.confirmed) {
        return;
      }

      this.deploymentScopesService
        .approveDeploymentScope(this.deploymentScopeId, result.comment)
        .pipe(
          catchError((err) => {
            this.toastService.showError('Failed to approve deployment scope');

            return throwError(() => err);
          }),
        )
        .subscribe((sub) => {
          this.toastService.showSuccess('Succesfully approved deployment scope!.');

          setTimeout(function () {
            window.location.reload();
          }, 2500);
        });
    });
  }

  onRejectDeploymentScope(): void {
    const dialogRef = this.dialog.open(ApproveOperationDialog, {
      data: {
        userEmail: 'test@chevron.com',
        headerText: 'Reject Deployment Scope',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirmed) {
        this.deploymentScopesService
          .rejectDeploymentScope(this.deploymentScopeId, result.comment)
          .pipe(
            catchError((err) => {
              this.toastService.showError('Failed to reject deployment scope.');

              return throwError(() => err);
            }),
          )
          .subscribe((sub) => {
            this.toastService.showSuccess('Succesfully rejected deployment scope!.');

            setTimeout(function () {
              window.location.reload();
            }, 2500);
          });
      }
    });
  }

  onAutomationProcessChanged(value: IAutomationProcessResponse) {
    if (!value) {
      return;
    }

    const allResourceTypes = this._automationProcessService.getResourceTypes();
    const automationProcessResourceTypes = allResourceTypes.filter((all) => value.resourceTypes.some((type) => type === all.value));

    this.resourceTypes = automationProcessResourceTypes;
    this.deploymentScope.value.resourceTypes.selectedTypes = [];
  }

  getMaintenanceSchedule(form: FormGroup<MaintenanceScheduleForm>): MaintenanceScheduleRequestModel {
    const value = form.value;

    const startTime = `${value.startTime.hours()}:${value.startTime.minutes()}`;
    const duration = `0.${value.duration.hours()}:${value.duration.minutes()}:00`;

    return {
      name: value.name,
      description: value.description,
      timeZone: value.timeZone,
      startTime: startTime,
      duration: duration,
      tagName: value.tagName,
      interval: value.interval,
      dayOfWeek: value.dayOfWeek,
      monthOccurrence: value.monthOccurrence,
      offsetInDays: value.offsetInDays,
    } as MaintenanceScheduleRequestModel;
  }

  automationProcessIsSelected(): boolean {
    return !!this.deploymentScope.value.automationProcess.automationProcess;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      const deploymentScopeId = params['deploymentScopeId'];

      if (deploymentScopeId) {
        this.deploymentScopeId = deploymentScopeId;
        this.readOnlyMode = true;

        this.deploymentScope.disable();

        this.deploymentScopesService.getDeploymentScope(deploymentScopeId).subscribe((sub: DeploymentScopeResponseModel) => {
          this.initializeFormValues(sub);
        });
      }
    });

    this.deploymentScopeDataService.automationProcessMessage.subscribe((sub) => this.onAutomationProcessChanged(sub));
  }

  initializeFormValues(deploymentScope: DeploymentScopeResponseModel): void {
    const automationProces = deploymentScope.automationProcess;

    const automationProcessValue = {
      id: automationProces.id,
      name: automationProces.name,
      description: automationProces.description,
      resourceTypes: [],
      approvalTypes: automationProces.approvalTypes,
      type: automationProces.type,
      requiresMaintenanceSchedule: true,
      businessCapability: {
        id: 1,
        name: automationProces.businessCapability,
        digitalPlatformName: automationProces.digitalPlatform,
        supportGroupName: automationProces.supportGroup,
      } as IAutomationProcessBusinessCapabilityDetails,
    } as IAutomationProcessResponse;

    this.deploymentScope.controls.automationProcess.setValue({
      automationProcess: automationProcessValue,
      description: automationProces.description,
      type: automationProces.type,
      approvalTypes: automationProces.approvalTypes.join(', '),
      businessCapability: automationProces.businessCapability,
      digitalPlatform: automationProces.digitalPlatform,
      supportGroup: automationProces.supportGroup,
    });

    this.deploymentScope.patchValue({
      inclusion: deploymentScope.inclusionType,
    });

    if (deploymentScope.maintenanceSchedule) {
      const schedule = deploymentScope.maintenanceSchedule;
      const momentValue = moment(schedule.startTime, ['HH:mm:ss:SSS']);
      const duration = moment(schedule.duration, ['HH:mm:ss']);

      this.deploymentScope.controls.maintenanceSchedule.patchValue({
        name: schedule.name,
        description: schedule.description,
        timeZone: schedule.timeZone,
        startTime: momentValue,
        duration: duration,
        tagName: schedule.tagName,
        interval: schedule.interval,
        dayOfWeek: schedule.dayOfWeek,
        monthOccurrence: schedule.monthOccurrence,
        offsetInDays: schedule.offsetInDays,
      });
    }

    this.deploymentScope.controls.tags.patchValue({
      tags: deploymentScope.tags,
    });

    const allResourceTypes = this._automationProcessService.getResourceTypes();
    const selectedTypes = allResourceTypes.filter((x) => deploymentScope.resourceTypes.includes(x.value));

    this.deploymentScope.controls.resourceTypes.patchValue({
      selectedTypes: selectedTypes,
    });

    this.initializeScopingValues(deploymentScope);
  }

  initializeScopingValues(deploymentScope: DeploymentScopeResponseModel): void {
    const scoping = this.deploymentScope.controls.scopingType;

    if (deploymentScope.resources) {
      const subscriptions = deploymentScope.resources
        .filter((x) => x.resourceType === 'Microsoft.Resources/subscriptions')
        .map((x) => {
          return {
            displayName: x.name,
            id: x.name,
            resourceId: x.id,
          } as IAzureSubscription;
        });

      const resourceGroups = deploymentScope.resources
        .filter((x) => x.resourceType === 'Microsoft.Resources/resourceGroups')
        .map((x) => {
          return {
            resourceId: x.id,
            name: x.name,
            tags: {}, // TODO: skipping tags for now here, maybe we won't even need them in read only view.
          } as IAzureResourceGroup;
        });

      const resources = deploymentScope.resources
        .filter((x) => x.resourceType != 'Microsoft.Resources/subscriptions' && x.resourceType != 'Microsoft.Resources/resourceGroups')
        .map((x) => {
          return {
            id: x.id,
            name: x.name,
            type: x.resourceType,
            tags: {}, // TODO: skipping tags for now here, maybe we won't even need them in read only view.
          } as IAzureResource;
        });

      scoping.controls.scopingType.setValue(DeploymentScopeType.Resources);
      scoping.controls.resources.patchValue({
        subscriptions: subscriptions,
        resourceGroups: resourceGroups,
        resources: resources,
      });
    } else if (deploymentScope.digitalPlatform) {
      const digitalPlatform = {
        id: deploymentScope.digitalPlatform.id,
        name: deploymentScope.digitalPlatform.name,
        shortName: deploymentScope.digitalPlatform.name,
      } as IDigitalPlatform;

      scoping.controls.scopingType.setValue(DeploymentScopeType.DigitalPlatform);
      scoping.controls.digitalPlatform.patchValue({
        applicationTiers: deploymentScope.digitalPlatform.tiers,
        selectedValues: [digitalPlatform], // TODO: needs improvement for supporting multiple digital platforms in the backend
      });
    }
  }
}
